

.form--panel {
    @extend .form;

    .button--cta {
        margin: 0;
    }

    .form--panel__input {
        .form--panel__input__container {
            margin-left: $indent-hor;
        }

        input {
            height: 4rem;
            width: 100%;
            border: none;
            border-bottom: 1px solid $color-border;
            font-size: $font-size-default;
            background: transparent;
            overflow: hidden;
            text-overflow: ellipsis;

            &::-webkit-input-placeholder {
                font-weight: $font-weight-bold;
            }

            &::-moz-placeholder {
                font-weight: $font-weight-bold;
            }

            &:-ms-input-placeholder {
                font-weight: $font-weight-bold;
            }

            &:-moz-placeholder {
                font-weight: $font-weight-bold;
            }
        }

        .form--panel__input__icon {
            position: absolute;
            left: 0;
            top: 1.2rem;
        }
    }

    @media (max-width: $screen-sm) {
        .button--cta {
            margin-top: $indent-m !important;
        }
    }

    @media (max-width: $screen-xs) {
        .form--panel__input {
            .form--panel__input__container {
                margin-left: 0;
            }

            .form--panel__input__icon {
                display: none;
            }
        }
    }
}
